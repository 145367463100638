<template>
  <div>
    <!--If there is any campaign invitations-->
    <v-container v-if="items.length" fluid grid-list-xl>
      <v-layout wrap>
        <v-flex
          v-for="(invite, index) in items"
          :key="index"
          md4
          sm6
          xs12
          lg4
        >
          <!-- add expired at feature -->
          <campaign-list-card
            :campaign="invite.campaign"
            :invite="invite"
            @accepted="getCampaignInvitations()"
            @declined="getCampaignInvitations()"
          ></campaign-list-card>
        </v-flex>
      </v-layout>
      <v-pagination
        v-model="currentPage"
        :length="total"
        v-if="total > 1"
        @input="getCampaignInvitations"
      ></v-pagination>
    </v-container>
    <!--If no campaign invitations-->
    <div
      v-else-if="!loading && !items.length"
      class="m-5 text-center"
    >
      <img
        v-if="invitation"
        src="@/assets/mailboxInfl.svg"
        alt="mail box"
        class="default-img"
      />
      <img
        v-else
        src="@/assets/paperplaneInfl.svg"
        alt="paper plane"
        class="default-img"
      />
    </div>

    <template v-if="loading">
      <v-container fluid grid-list-xl>
        <v-layout wrap>
          <v-flex v-for="item in 3" :key="item" md4 sm6 xs12>
            <v-skeleton-loader
              type="image, article, list-item-two-line, list-item-two-line, list-item-two-line, actions"
            >
            </v-skeleton-loader>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CampaignListCard from "../../cards/CampaignListCard";

export default {
  components: { CampaignListCard },
  props: {
    invitation: {
      default: true,
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    items: [],
    currentPage: 1,
    total: null,
    perPage: 15
  }),
  methods: {
    ...mapActions("loading", ["setPageLoading"]),
    ...mapActions("core/campaigns", ["getCampaignRequestsForInfluencer"]),
    getCampaignInvitations() {
      this.loading = true;
      this.items = [];

      const params = {
        request_type: "pending_applications",
        page: this.currentPage,
        limit: this.perPage,
      };

      if (this.invitation) {
        params.request_type = "pending_invitations";
      }

      this.getCampaignRequestsForInfluencer(params).then(campaignRequests => {
        this.items = campaignRequests.data;
        this.total = campaignRequests.lastPage;
        this.loading = false;
      });
    },
  },
  created() {
    this.getCampaignInvitations();
  },
  destroyed() {
    this.setPageLoading(false);
  }
};
</script>
