// Format numbers to local country standards
export default function(reason) {
  switch (reason) {
    case 'system_invitation_expired':
      return "Invitation expired";
    case 'system_campaign_full':
      return "Campaign full";
    case 'not_enough_impressions':
      return "Not enough impressions";
    case 'unfit_follower_demographic':
      return "Unfit follower demographics"
    case "unfit_for_brand":
      return "Unfit for brand";
    default:
      return "Other";
  }
}