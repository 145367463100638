<template>
  <div>
    <v-container fluid grid-list-xl>
      <v-layout wrap>
        <v-flex v-if="!loading && items.length === 0" xs12>
          <div class="text-center m-5">
            <img
              src="@/assets/paperplaneInfl.svg"
              class="default-img"
              alt="paper plane"
            />
          </div>
        </v-flex>
        <template v-if="!loading">
          <v-flex v-for="item in items" :key="item.campaign.uuid" md4 sm6 xs12>
            <campaign-rejection-card
              :campaign-request="item"
              flat
              class="bordered"
              @showReason="selectedInvitation = $event">
              <template v-slot:footer>
                <v-btn
                  outlined
                  block
                  color="primary"
                  :to="{ name: 'campaign', params: { id: item.campaign.uuid } }"
                >
                  See campaign
                </v-btn>
              </template>
            </campaign-rejection-card>
          </v-flex>
        </template>
        <template v-if="loading">
          <v-flex v-for="item in 3" :key="item" md4 sm6 xs12>
            <v-skeleton-loader
              type="image, article, list-item-two-line, list-item-two-line, list-item-two-line, actions"
            >
            </v-skeleton-loader>
          </v-flex>
        </template>
      </v-layout>
      <v-pagination
        v-model="currentPage"
        :length="total"
        v-if="total > 1"
        @input="getDeclinedRequests"
      ></v-pagination>
    </v-container>
    <campaign-rejection-dialog
      v-if="selectedInvitation !== null"
      v-model="showDialog"
      :invitation="selectedInvitation"
    ></campaign-rejection-dialog>
  </div>
</template>

<script>
import CampaignRejectionDialog from "../dialogs/CampaignRejectionDialog";
import CampaignRejectionCard from "../../cards/CampaignRejectionCard";
import { mapActions } from "vuex";

export default {
  components: { CampaignRejectionCard, CampaignRejectionDialog },
  data: () => ({
    loading: false,
    items: [],
    currentPage: 1,
    total: null,
    perPage: 15,
    selectedInvitation: null
  }),
  computed: {
    showDialog: {
      get() {
        return !!this.selectedInvitation;
      },
      set() {
        this.selectedInvitation = null;
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["getCampaignRequestsForInfluencer"]),
    getDeclinedRequests() {
      this.loading = true;
      this.items = [];

      const params = {
        request_type: "declined",
        page: this.currentPage,
        limit: this.perPage,
      };

      this.getCampaignRequestsForInfluencer(params).then(campaignRequests => {
        this.items = campaignRequests.data;
        this.total = campaignRequests.lastPage;
        this.loading = false;
      });
    }
  },
  created() {
    this.getDeclinedRequests();
  }
};
</script>
