<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap>
      <v-flex v-if="!loading && campaigns.length === 0" md4 sm6 xs12>
        No completed campaigns
      </v-flex>
      <template v-if="!loading">
        <v-flex v-for="(campaign, index) in campaigns" :key="index" md4 sm6 xs12>
          <campaign-list-card
            :campaign="campaign"
            flat
            class="bordered"
            :fields="[
              'channel',
              'status',
              'starting_at',
              'ending_at',
              'influencers'
            ]"
          >
            <template v-slot:footer>
              <v-btn
                outlined
                block
                color="primary"
                :to="{ name: 'campaign', params: { id: campaign.uuid } }"
              >
                See campaign
              </v-btn>
            </template>
          </campaign-list-card>
        </v-flex>
      </template>
      <template v-if="loading">
        <v-flex v-for="item in 3" :key="item" md4 sm6 xs12>
          <v-skeleton-loader
            type="image, article, list-item-two-line, list-item-two-line, list-item-two-line, actions"
          >
          </v-skeleton-loader>
        </v-flex>
      </template>
    </v-layout>
    <v-pagination
      v-if="!loading"
      v-model="page"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CampaignListCard from "../../cards/CampaignListCard";

export default {
  components: { CampaignListCard },
  props: {
    business: {
      required: false
    }
  },
  data: () => ({
    campaigns: [],
    loading: true,
    page: 1,
    limit: 15,
    total: null
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("filters", ["selectedCountries"]),
    ...mapGetters("core/auth", ["isInfluencer", "isAgency"])
  },
  watch: {
    business: {
      immediate: true,
      handler() {
        this.getCampaigns();
      }
    },
    selectedCountries: {
      immediate: true,
      handler() {
        this.getCampaigns();
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", [
      "loadCampaigns",
      "loadCampaignsSummary",
      "getCampaignRequestsForInfluencer",
    ]),
    handlePageChange(value) {
      this.getCampaigns(value);
    },
    getCampaigns(page = 1) {

      if (this.isInfluencer) {
        this.page = page;
        this.loading = true;

        const params = {
          request_type: "ended_campaigns",
          page: this.page,
          limit: this.limit,
        };

        this.getCampaignRequestsForInfluencer(params).then(campaignRequests => {
          const campaigns = [];

          campaignRequests.data.forEach(campaignRequest => {
            campaigns.push(campaignRequest.campaign);
          });

          this.campaigns = campaigns;
          this.total = campaignRequests.lastPage;
          this.loading = false;
        });
      } else {
        const setCampaignsAction = params =>
          this.isAgency
          ? this.loadCampaignsSummary(params)
          : this.loadCampaigns(params);
        
        this.page = page;
        this.loading = true;

        let params = {
          active_campaigns: false,
          page: this.page,
          limit: this.limit
        };

        if (this.selectedCountries.length) {
          params.country_codes = this.selectedCountries;
        }

        if (this.business) {
          params.business_uuid = this.business.uuid;
        }

        setCampaignsAction(params).then(campaigns => {
          this.campaigns = campaigns.response;
          this.total = campaigns.lastPage;
          this.loading = false;
        });
      }
    }
  }
};
</script>
