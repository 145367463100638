<template>
  <div>
    <div v-if="!isInfluencer">
      <v-select
        v-model="mySelectedCountries"
        label="Countries"
        :items="countries"
        item-text="name"
        item-value="iso_3166_2"
        :loading="!countries.length"
        multiple
        @change="updateSelectedCountries"
      ></v-select>
    </div>
    <div v-if="$vuetify.breakpoint.xsOnly" class="mt-8">
      <v-flex xs12>
        <v-select
          :items="tabs"
          v-model="selected"
          outlined
          :height="44"
          :color="'black'"
          :item-color="'primary'"
        >
          <!-- Selected -->
          <template v-slot:selection="{ item }">
            <v-list-item-avatar>
              <v-icon :color="'primary'">
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span v-html="item.label"></span>
                <v-chip x-small class="ml-1 pr-1 pl-1 mb-2" color="accent" v-if="item.count">{{ item.count | friendly_numbers }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <!--List item-->
          <template slot="item" slot-scope="prop">
            <v-list-item-avatar>
              <v-icon
                :class="prop.item.name !== selected.name ? 'not-selected' : ''"
                >{{ prop.item.icon }}</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="prop.item.name !== selected.name ? 'not-selected' : ''">
                <span v-html="prop.item.label"></span>
                <v-chip x-small class="ml-1 pr-1 pl-1 mb-1" color="accent" v-if="prop.item.count">{{ prop.item.count | friendly_numbers }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-flex>
      <div>
        <active-campaigns-tab
          v-if="selected.name === 'active_campaigns'"
          :business="business"
        ></active-campaigns-tab>
        <ended-campaigns-tab
          v-else-if="selected.name === 'ended_campaigns'"
          :business="business"
        ></ended-campaigns-tab>
        <draft-campaigns-tab
          :title="title"
          v-else-if="selected.name === 'draft_campaigns'"
          :business="business"
        ></draft-campaigns-tab>
        <campaign-invitations
          v-else-if="selected.name === 'invintation_campaigns'"
          :invitation="true"
        ></campaign-invitations>
        <campaign-invitations
          v-else-if="selected.name === 'application_campaigns'"
          :invitation="false"
        ></campaign-invitations>
        <campaign-rejections
          v-else-if="selected.name === 'rejected_campaigns'"
        ></campaign-rejections>
        <v-alert v-else :value="true" type="warning"
          >Under construction</v-alert
        >
      </div>
    </div>

    <v-tabs
      v-model="selectedTab"
      icons-and-text
      background-color="grey lighten-4"
      class="elevation-1 mt-8"
      :vertical="vertical"
      :grow="grow"
      v-else
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <template v-for="tab in tabs">
        <v-tab class="accent--text" :key="tab.name">
          {{ tab.label }}
          <v-badge
            color="accent"
            top
            right
            overlap
            offset-x="-5px"
            :value="!!tab.count"
          >
            <template v-slot:badge>
              {{ tab.count | friendly_numbers }}
            </template>
            <v-icon color="accent">{{ tab.icon }}</v-icon>
          </v-badge>
        </v-tab>
      </template>
      <v-tabs-items v-model="selectedTab" touchless>
        <v-tabs-items
          v-model="selectedTab"
          touchless
          class="elevation-0 transparent"
        >
          <v-tab-item v-for="item in tabs" :key="item.name">
            <active-campaigns-tab
              v-if="item.name === 'active_campaigns'"
              :business="business"
            ></active-campaigns-tab>
            <ended-campaigns-tab
              v-else-if="item.name === 'ended_campaigns'"
              :business="business"
            ></ended-campaigns-tab>
            <draft-campaigns-tab
              :title="title"
              v-else-if="item.name === 'draft_campaigns'"
              :business="business"
            ></draft-campaigns-tab>
            <campaign-invitations
              v-else-if="item.name === 'invintation_campaigns'"
              :invitation="true"
            ></campaign-invitations>
            <campaign-invitations
              v-else-if="item.name === 'application_campaigns'"
              :invitation="false"
            ></campaign-invitations>
            <campaign-rejections
              v-else-if="item.name === 'rejected_campaigns'"
            ></campaign-rejections>
            <v-alert v-else :value="true" type="warning"
              >Under construction</v-alert
            >
          </v-tab-item>
        </v-tabs-items>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ActiveCampaignsTab from "./ActiveCampaignsTab";
import EndedCampaignsTab from "./EndedCampaignsTab";
import DraftCampaignsTab from "./DraftCampaignsTab";
import CampaignInvitations from "../../influencer/pages/CampaignInvitations";
import CampaignRejections from "../../influencer/pages/CampaignRejections";
import friendly_numbers from "@/helpers/filters/friendly_numbers";

export default {
  components: {
    DraftCampaignsTab,
    EndedCampaignsTab,
    ActiveCampaignsTab,
    CampaignInvitations,
    CampaignRejections
  },
  filters: {
    friendly_numbers,
  },
  props: {
    vertical: {
      required: false,
      type: Boolean,
      default: false
    },
    grow: {
      required: false,
      type: Boolean,
      default: true
    },
    business: {
      required: false
    }
  },
  data: () => ({
    selectedTab: null,
    selectedDropDown: {
      name: "active_campaigns",
      label: "Active",
      icon: "fal fa-calendar-check",
      count: 0
    },
    countries: [],
    mySelectedCountries: [],
  }),
  computed: {
    ...mapGetters("core/auth", ["isInfluencer"]),
    ...mapState("core/auth", ["user"]),
    ...mapState("filters", ["selectedCountries"]),

    title() {
      return !this.isInfluencer
        ? "You have no campaign drafts"
        : "You have no pending campaigns";
    },
    tabs() {
      // Tab names are linked to from emails!
      // Avoid changing
      let tabs = [
        {
          name: "active_campaigns",
          label: "Active",
          icon: "fal fa-calendar-check",
          count: 0
        },
        {
          name: "ended_campaigns",
          label: "Completed",
          icon: "fal fa-calendar-minus",
          count: 0
        },
        {
          name: "draft_campaigns",
          label: "Drafts",
          icon: "fal fa-calendar-edit",
          count: 0
        }
      ];
      if (this.isInfluencer) {
        tabs = [
          {
            name: "active_campaigns",
            label: "Active",
            icon: "fal fa-calendar-check",
            count: 0
          },
          {
            name: "draft_campaigns",
            label: "Pending",
            icon: "fal fa-stopwatch",
            count: 0
          },
          {
            name: "invintation_campaigns",
            label: "Invitations",
            icon: "fal fa-envelope",
            count: 0
          },
          {
            name: "application_campaigns",
            label: "Applications",
            icon: "fal fa-bell",
            count: 0
          },
          {
            name: "ended_campaigns",
            label: "Completed",
            icon: "fal fa-calendar-minus",
            count: 0
          },
          {
            name: "rejected_campaigns",
            label: "Rejections",
            icon: "fal fa-ban",
            count: 0
          }
        ];
      }
      return tabs;
    },
    selected: {
      get() {
        return this.selectedDropDown;
      },
      set(value) {
        this.selectedDropDown = value;
      }
    },
  },
  methods: {
    ...mapActions("core", ["getCountries"]),
    ...mapActions("core/campaigns", ["loadCampaigns", "getCampaignRequestCountsForInfluencer"]),
    ...mapActions("filters", ["setSelectedCountries"]),
    updateSelectedCountries() {
      this.setSelectedCountries(this.mySelectedCountries);
      this.getCampaigns();
    },
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        countries => {
          this.countries = countries;
          this.updateSelectedCountries();
        },
        error => {
          console.log(error);
        }
      );
    },
    getCampaigns() {
      const params = {
        draft: true,
        page: 1,
        limit: 15
      };
      if (this.selectedCountries.length) {
        params.country_codes = this.selectedCountries;
      }
      this.loadCampaigns(params).then(campaigns => {
        this.tabs[2].count = campaigns.total;
        this.$forceUpdate();
      });
    },
    getCountsForInfluencers() {
      this.getCampaignRequestCountsForInfluencer().then(campaignRequestCounts => {
        this.tabs[0].count = campaignRequestCounts.activeCampaignsCount;
        this.tabs[1].count = campaignRequestCounts.pendingCampaignsCount;
        this.tabs[2].count = campaignRequestCounts.pendingInvitationsCount;
        this.tabs[3].count = campaignRequestCounts.pendingApplicationsCount;
        // this.tabs[4].count = campaignRequestCounts.endedCampaignsCount;
        // this.tabs[5].count = campaignRequestCounts.declinedCount;
        this.$forceUpdate();
      });
    },
    setSelectedTabFromUrl() {
      let linkedTab = this.$route.query.tab;

      if(linkedTab) {
        let tab = this.tabs.filter(tabs => tabs.name === linkedTab);

        if(tab.length > 0) {
          this.selectedTab = this.tabs.findIndex(
            tabs => tabs.name === linkedTab
          );
          this.selectedDropDown = tab[0];
        }
      }
    }
  },
  created() {
    this.setSelectedTabFromUrl();

    if (this.isInfluencer) {
      this.getCountsForInfluencers();
    } else {
      this.loadActiveCountries();
      this.getCampaigns();
    }
  }
};
</script>
<style lang="scss">
.select-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  background-color: #9c27b036;
}
.not-selected {
  color: black !important;
  background-color: transparent;
  .v-icon {
    color: black !important;
    .v-list-item__title {
      color: black !important;
    }
  }
}
.v-list-item--link:before {
  background-color: transparent;
}
.v-menu__content {
  max-height: 404px !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid grey;
}
</style>
