<template>
  <v-dialog v-model="showing" width="100%" max-width="500px">
    <v-card>
      <v-card-title>
        <div class="headline">Campaign rejection</div>
      </v-card-title>

      <v-card-text>
        <div class="subheading font-weight-bold">Message from the brand</div>

        <div class="pre-line">
          {{
            invitation.declined_reason_text
              ? invitation.declined_reason_text
              : "The brand has not attached a message at rejection"
          }}
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showing = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  components: {},
  filters: {
    local_numbers
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    invitation: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    dialog: false,
    note: ""
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
