<template>
  <v-card class="flex-card" height="100%" :flat="flat">
    <router-link
        :to="{
        name: 'campaign',
        params: { id: campaignRequest.campaign.uuid }
      }"
      >
      <campaign-image
        :src="campaignRequest.campaign.image ? campaignRequest.campaign.image.url : campaignRequest.campaign.image_url"
        :campaign="campaignRequest.campaign"
        :height="185"
        class="image-border-radius campaign-list-image"
      ></campaign-image>
    </router-link>
    <v-divider></v-divider>
    <v-card-text class="grow campaign-card-content">
      <div
        class="mb-2 d-flex justify-space-between"
        style="height: 60px"
      >
        <div class="subtitle-1 font-weight-medium mb-0 text--primary">
          {{ campaignRequest.campaign.name }}
        </div>
        <div class="mb-2 d-flex justify-space-between">
          <div v-if="campaignRequest.campaign.hidden" class="body-2 mb-0 ml-2">
            <v-icon small>fal fa-eye-slash</v-icon>
          </div>
        </div>
      </div>

      <v-list two-line>

        <v-list-item>
          <v-list-item-action>
            <v-icon color="primary">fal {{campaignRequest.invitation ? "fa-envelope" : "fa-bell"}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ campaignRequest.invitation ? "Invitation" : "Application" }}
            </v-list-item-title>
            <v-list-item-subtitle>Request type</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="showReason()">
          <v-list-item-action>
            <v-icon color="primary">fal fa-comment-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span>{{ campaignRequest.declined_reason | rejection_reasons }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>Rejection reason</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>
    </v-card-text>

    <v-card-actions v-if="$slots.footer">
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import CampaignImage from "../images/CampaignImage";
import rejection_reasons from "@/helpers/filters/rejection_reasons";

export default {
  components: { CampaignImage },
  filters: { rejection_reasons },
  props: {
    campaignRequest: {
      required: true
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    showReason() {
      this.$emit("showReason", this.campaignRequest);
    }
  }
}
</script>

<style lang="scss" scoped>
.image-border-radius {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.campaign-list-image {
  height: 185px;
}
.campaign-card-content {
  padding: 16px !important;
}
</style>