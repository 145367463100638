<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap>
      <v-flex v-if="!loading && campaigns.length === 0" xs12>
        No campaign drafts.
      </v-flex>
      <template v-if="!loading">
        <v-flex v-for="campaign in campaigns" :key="campaign.uuid" md4 sm6 xs12>
          <campaign-list-card
            :campaign="campaign"
            flat
            class="bordered"
            :fields="[
              'channel',
              'status',
              'starting_at',
              'ending_at',
              'influencers'
            ]"
          >
            <template v-slot:footer>
              <v-btn
                outlined
                block
                color="primary"
                :to="{ name: isInfluencer ? 'campaign' : 'campaign.create', params: { id: campaign.uuid } }"
              >
                See campaign
              </v-btn>
            </template>
          </campaign-list-card>
        </v-flex>
      </template>
      <template v-if="loading">
        <v-flex v-for="item in 3" :key="item" md4 sm6 xs12>
          <v-skeleton-loader
            type="image, article, list-item-two-line, list-item-two-line, list-item-two-line, actions"
          >
          </v-skeleton-loader>
        </v-flex>
      </template>
      <v-pagination
        v-model="currentPage"
        :length="total"
        v-if="total > 1"
        @input="getCampaigns"
      ></v-pagination>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CampaignListCard from "../../cards/CampaignListCard";

export default {
  components: { CampaignListCard },
  props: {
    business: {
      required: false
    },
    title: {
      required: true
    }
  },
  data: () => ({
    campaigns: [],
    loading: true,
    currentPage: 1,
    total: null,
    perPage: 15
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("filters", ["selectedCountries"]),
    ...mapGetters("core/auth", ["isInfluencer", "isAgency"])
  },
  watch: {
    business: {
      immediate: true,
      handler() {
        this.getCampaigns();
      }
    },
    selectedCountries: {
      immediate: true,
      handler() {
        this.getCampaigns();
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", [
      "loadCampaigns",
      "loadCampaignsSummary",
      "getCampaignRequestsForInfluencer",
    ]),
    getCampaigns() {
      if (this.isInfluencer) {
        const params = {
          request_type: "pending_campaigns",
          page: this.currentPage,
          limit: this.perPage,
        };

        this.loading = true;

        this.getCampaignRequestsForInfluencer(params).then(campaignRequests => {
          const campaigns = [];

          campaignRequests.data.forEach(campaignRequest => {
            campaigns.push(campaignRequest.campaign);
          });

          this.campaigns = campaigns;
          this.total = campaignRequests.lastPage;
          this.loading = false;
        });
      } else {
        const setCampaignsAction = params =>
          this.isAgency
            ? this.loadCampaignsSummary(params)
            : this.loadCampaigns(params);

        this.loading = true;
        let params = {
          draft: true,
          page: this.currentPage,
          limit: this.perPage
        };
        if (this.selectedCountries.length) {
          params.country_codes = this.selectedCountries;
        }
        if (this.business) {
          params.business_uuid = this.business.uuid;
        }
        setCampaignsAction(params).then(campaigns => {
          this.campaigns = campaigns.response;
          this.total = campaigns.lastPage;
          this.loading = false;
        });
      }
    },
  }
};
</script>
